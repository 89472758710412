import {
	getAnalytics,
	Types,
	transformMembershipToProduct,
	transformTopUpToProduct,
	WebTracking,
	EventTransformers
} from 'common';
import type {
	Event,
	EventBookingPurchaseInfo,
	EventContent,
	EventItemsSelectionItem,
	MembershipPurchaseInfo
} from '../booking-utils/types';

import {
	transformEventItemToProduct,
	transformSelectedItemToProduct,
	transformToCompletedOrder,
	transformToOrderStarted
} from './segmentTransformers';

export const ticketAddedToCart = async (
	event: Event,
	eventItem: EventItemsSelectionItem,
	content: EventContent,
	locale: Locale
) => {
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const product = transformEventItemToProduct(event, eventItem, content, locale);
	analytics.web.productAdded(product);
};

export const eventOrderStarted = async (props: {
	event: Event;
	selectedItems: Array<EventItemsSelectionItem>;
	content: EventContent;
	totalPrice: number;
	locale: Locale;
}) => {
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const { event, selectedItems, content, ...rest } = props;
	const products = selectedItems.map((item) =>
		transformEventItemToProduct(event, item, content, rest.locale)
	);
	const orderStarted: WebTracking.OrderStarted = transformToOrderStarted({
		...rest,
		event,
		currency: event.currency,
		category: 'COURSE',
		...(event.productCategory?.code ? { main_speciality: event.productCategory.code } : {}),
		main_speciality: event.productCategory.code,
		products
	});
	analytics.web.orderStarted(orderStarted);
};

export const membershipOrderStarted = async (membership: Types.Membership) => {
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const product = transformMembershipToProduct(membership);
	const orderStarted: WebTracking.OrderStarted = transformToOrderStarted({
		totalPrice: membership.price,
		category: 'MEMBERSHIP',
		currency: membership.currency,
		products: [product]
	});
	analytics.web.orderStarted(orderStarted);
};

export const topUpOrderStarted = async (props: {
	topUp: Types.MembershipItem;
	membership: Types.Membership;
	locale: Locale;
	buyLink?: string;
	index?: number;
}) => {
	const { membership, topUp, ...rest } = props;
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const product = transformTopUpToProduct(props);
	const orderStarted: WebTracking.OrderStarted = transformToOrderStarted({
		...rest,
		totalPrice: typeof topUp.price === 'number' ? topUp.price : 0,
		category: 'TOPUP',
		currency: membership.currency,
		products: [product]
	});
	analytics.web.orderStarted(orderStarted);
};

export const checkoutStepCompleted = (props: {
	eventBookingPurchaseInfo: EventBookingPurchaseInfo | null | undefined;
	membershipPurchaseInfo: MembershipPurchaseInfo | null | undefined;
	step: WebTracking.CheckoutStepCompletedStep;
	paymentMethod?: string | undefined;
	content: EventContent | undefined;
	locale: Locale;
}) => {
	const {
		step,
		paymentMethod = undefined,
		locale,
		eventBookingPurchaseInfo,
		membershipPurchaseInfo,
		content
	} = props;
	if (eventBookingPurchaseInfo) {
		const { event, selectedItems, totalPrice, promoCodeApplied } = eventBookingPurchaseInfo;
		eventCheckoutStepCompleted({
			event,
			selectedItems,
			content,
			totalPrice,
			step,
			paymentMethod,
			coupon: promoCodeApplied?.code,
			locale
		});
	}

	if (
		membershipPurchaseInfo &&
		membershipPurchaseInfo.membership &&
		!membershipPurchaseInfo.topUp
	) {
		const { membership, totalPrice, promoCodeApplied } = membershipPurchaseInfo;
		membershipCheckoutStepCompleted({
			membership,
			totalPrice,
			step,
			coupon: promoCodeApplied?.code,
			paymentMethod: paymentMethod
		});
	}

	if (
		membershipPurchaseInfo &&
		membershipPurchaseInfo.membership &&
		membershipPurchaseInfo.topUp
	) {
		const { membership, topUp, totalPrice, promoCodeApplied } = membershipPurchaseInfo;
		topUpCheckoutStepCompleted({
			membership,
			topUp,
			totalPrice,
			step,
			paymentMethod,
			locale,
			coupon: promoCodeApplied?.code
		});
	}
};

export const eventCheckoutStepCompleted = async (props: {
	event: Event;
	selectedItems: Array<EventItemsSelectionItem>;
	content: EventContent | undefined;
	totalPrice: number;
	step: WebTracking.CheckoutStepCompletedStep;
	paymentMethod: string | undefined;
	coupon: string | undefined;
	locale: Locale;
}) => {
	const { event, selectedItems, totalPrice, step, coupon, paymentMethod, content, locale } =
		props;
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const products = selectedItems.map((item) =>
		transformEventItemToProduct(event, item, content, locale)
	);
	analytics.web.checkoutStepCompleted({
		step,
		currency: event.currency,
		payment_method: paymentMethod,
		value: totalPrice / 100,
		products,
		...(coupon ? { coupon } : undefined),
		brand: EventTransformers.transformEventFormatToContentBrand(event.eventFormat),
		category: 'COURSE',
		total: totalPrice / 100
	});
};

export const membershipCheckoutStepCompleted = async (props: {
	membership: Types.Membership;
	totalPrice: number;
	step: WebTracking.CheckoutStepCompletedStep;
	paymentMethod: string | undefined;
	coupon: string | undefined;
}) => {
	const { membership, totalPrice, step, coupon, paymentMethod } = props;
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const products = [transformMembershipToProduct(membership)];
	analytics.web.checkoutStepCompleted({
		step,
		currency: membership.currency,
		value: totalPrice / 100,
		payment_method: paymentMethod,
		products,
		...(coupon ? { coupon } : undefined),
		category: 'MEMBERSHIP',
		total: totalPrice / 100
	});
};

export const topUpCheckoutStepCompleted = async (props: {
	membership: Types.Membership;
	topUp: Types.MembershipItem;
	totalPrice: number;
	step: WebTracking.CheckoutStepCompletedStep;
	paymentMethod: string | undefined;
	coupon: string | undefined;
	locale: Locale;
}) => {
	const { membership, topUp, totalPrice, step, coupon, paymentMethod, locale } = props;
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const products = [transformTopUpToProduct({ topUp, membership, locale })];
	analytics.web.checkoutStepCompleted({
		step,
		currency: membership.currency,
		value: totalPrice / 100,
		payment_method: paymentMethod,
		products,
		...(coupon ? { coupon } : undefined),
		category: 'TOPUP',
		total: totalPrice / 100
	});
};

export const eventOrderCompleted = async (props: {
	event: Event;
	selectedItems: Array<EventItemsSelectionItem>;
	content: EventContent | undefined;
	bookingId: string;
	clientReference: string;
	totalPrice: number;
	tax?: number;
	coupon?: string;
	discount?: number;
	main_speciality: string | undefined;
	specialities: Array<string>;
	locale: Locale;
}) => {
	const { event, selectedItems, content, ...rest } = props;
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const products = selectedItems.map((selectedItem) =>
		transformSelectedItemToProduct(event, selectedItem, content, rest.locale)
	);
	const completedOrderData = transformToCompletedOrder({
		...rest,
		event,
		currency: event.currency,
		products,
		category: 'COURSE'
	});
	analytics.web.orderCompleted(completedOrderData);
};

export const membershipOrderCompleted = async (props: {
	membership: Types.Membership;
	bookingId: string;
	clientReference: string;
	totalPrice: number;
	tax?: number;
	coupon?: string;
	discount?: number;
	locale: Locale;
}) => {
	const { membership, ...rest } = props;
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const products = [transformMembershipToProduct(membership)];
	const completedOrderData = transformToCompletedOrder({
		...rest,
		currency: membership.currency,
		products,
		category: 'MEMBERSHIP'
	});
	analytics.web.orderCompleted(completedOrderData);
};

export const topUpOrderCompleted = async (props: {
	membership: Types.Membership;
	topUp: Types.MembershipItem;
	bookingId: string;
	clientReference: string;
	totalPrice: number;
	tax?: number;
	coupon?: string;
	discount?: number;
	locale: Locale;
}) => {
	const { membership, topUp, locale, ...rest } = props;
	const analytics = await getAnalytics();
	if (!analytics) {
		return;
	}
	const products = [transformTopUpToProduct({ topUp, membership, locale })];
	const completedOrderData = transformToCompletedOrder({
		...rest,
		currency: membership.currency,
		products,
		category: 'TOPUP',
		locale
	});
	analytics.web.orderCompleted(completedOrderData);
};
